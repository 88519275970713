import colors, { useColors } from './index';

// ! KEEP STATIC AND HOOK FOR TS TYPING

export const usePalette = (themePalette) => {

  const newColors = useColors(themePalette)

  const palette = {
    primary: newColors.blue100,
    secondary: newColors.blue100,
    tertiary: newColors.black100,
    error: '',
    warning: newColors.red100,
    info: newColors.brown,
    success: newColors.green100,
    textPrimary: newColors.black100,
    textSecondary: newColors.gray400,
    textWhite: newColors.white,
  };

  return palette
}

const palette = {
  primary: colors.blue100,
  secondary: colors.blue100,
  tertiary: colors.black100,
  error: '',
  warning: colors.red100,
  info: colors.brown,
  success: colors.green100,
  textPrimary: colors.black100,
  textSecondary: colors.gray400,
  textWhite: colors.white,
};

export default palette;
