import React from 'react';
import ReactDOM from 'react-dom'

import App from './App';

import 'jam-icons/js/jam.min'
import 'jam-icons/css/jam.min.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'antd/dist/antd.css';

import 'sendbird-uikit/dist/index.css';
import 'lib/fonts/index.css';
import 'react-phone-input-2/lib/style.css';

// const container = document.getElementById('root');
// const root = createRoot(container);

ReactDOM.render(
  <React.StrictMode>
    <App name="Alakine" />
  </React.StrictMode>,
  document.getElementById('root')
);
