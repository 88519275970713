import React, { createContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider as StyleThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { useCustomTheme } from 'lib/theme';
import GlobalStyles from './GlobalStyles';
import { default as defaultColors } from 'lib/colors';
import { selectors as authSelectors } from 'services/auth';
import logo from 'lib/icons/logo.png';
import { useSelector } from 'react-redux';

const whiteLabelColors =
  JSON.parse(localStorage.getItem('whiteLabelColors')) || defaultColors;

export const ThemeContext = createContext({
  themePalette: defaultColors,
  setThemePalette: (palette) => {},
  defaultColors: defaultColors,
});

function ThemeProvider({ children }) {
  const [themePalette, setThemePalette] = useState(whiteLabelColors);
  const { whiteLabelLoginLogoUrl } = useSelector(authSelectors.getLogo);

  const theme = useCustomTheme(themePalette);

  useEffect(() => {
    const link = document.querySelector("link[rel~='icon']");
    link.href = whiteLabelLoginLogoUrl || logo; //localStorage.getItem('whiteLabelLoginLogoUrl') || logo;
    /* const title = document.querySelector('title');
    title.innerText = localStorage.getItem('fullName') || 'Alakin'; */
  }, []);

  return (
    <ThemeContext.Provider
      value={{ themePalette, setThemePalette, defaultColors }}
    >
      <StyleThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <GlobalStyles />
          {children}
        </MuiThemeProvider>
      </StyleThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
