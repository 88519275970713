import { alpha, lighten } from '@mui/material';

// ! KEEP STATIC AND HOOK FOR TS TYPING

export const useColors = (themePalette) => {
  const {
    primary,
    bgPrimary,

    purple,
    bgPurple,

    gray,
    gray100,
    gray200,
    gray300,
    gray400,
    gray500,

    red,
    redAlert,
    lightRed,
    bgRedLight,
    bgRedDark,

    green,
    lightGreen,
    darkGreen,
    brightGreen,
    bgGreen,

    yellow,
    bgYellow,

    orange,
    bgOrange,

    brown,
  } = themePalette;

  return {
    black: '#000000',
    black100: '#222222',
    black200: '#2C2C2C',
    white: '#ffffff',

    blue100: primary || colors.blue100,
    blue200: bgPrimary || primary ? lighten(primary, 0.7) : colors.blue200,

    purple200: purple || colors.purple200,
    bgPurple: bgPurple || colors.bgPurple,

    gray: gray || colors.gray,
    gray100: gray100 || colors.gray100,
    gray200: gray200 || colors.gray200,
    gray300: gray300 || colors.gray300,
    gray400: gray400 || colors.gray400,
    gray500: gray500 || colors.gray500,

    green100: green || colors.green100,
    green300: lightGreen || colors.green300,
    green400: darkGreen || colors.green400,
    green600: brightGreen || colors.green600,
    bgGreen: bgGreen || colors.bgGreen,

    red100: red || colors.red100,
    red200: redAlert || colors.red200,
    red300: lightRed || colors.red300,
    bgRed100: bgRedLight || colors.bgRed100,
    bgRed200: bgRedDark || colors.bgRed200,

    yellow100: yellow || colors.yellow100,
    yellow500: lighten(bgYellow || colors.bgYellow, 0.8),
    bgYellow: bgYellow || colors.bgYellow,

    orange100: orange || colors.orange100,
    bgOrange: bgOrange || colors.bgOrange,

    brown: brown || colors.brown,

    // ? very specific colors so not set dynamically
    jadeGreen100: '#27bf81',
    turquoise: '#2eba9f',
    lightNavyBlue: '#4E667F', // used for image bg in schedule page (event / appointment dialog)
    bluePurple: '#A6C1F5',
    grayPurple: '#b6bdd7',
    pink: '#F7A5F7',
  };
};

// DO NOT ERASE, USED TO ASSERT THE TYPES
const colors = {
  black: '#000000',
  black100: '#222222',
  black200: '#2C2C2C',
  white: '#ffffff',

  // blue000: '#0077ff', // replaced by blue 100
  blue100: '#2164E8',
  blue200: '#E9EFFC',
  // blue300: '#134bbb',//* darken 0.3
  // blue400: '#3B5998', // replaced by blue 100
  // blue500: '#40A9FF', // replaced by blue 100
  // blue600: '#4D83EC', //* Lighten 0.2

  // lightblue100: '#F7F7F7', // no uses
  // lightblue200: '#F3F5F9',// lighten(colors.blue100, 0.95)
  bluePurple: '#A6C1F5', // used once in unused component

  lightNavyBlue: '#4E667F', // used for image bg in schedule page (event / appointment dialog)

  purple200: '#D0ADF5',

  grayPurple: '#b6bdd7', // used once for "videoSmall" ? could not reproduce
  // grayPurple100: '#9797A7', // used gray instead

  pink: '#F7A5F7', // used once in draft-js editor. could not reproduce

  gray: '#AAAAAA',
  gray100: '#f6f6f6',
  gray200: '#ececec',
  // gray250: '#ebebeb', // replaced with gray200
  gray300: '#c8c8c8',
  // gray350: '#909090', // replaced by gray
  gray400: '#777777',
  gray500: '#3c4043',

  // green: '#3CFD3E', // replaced by green600
  green100: '#00AE4F',
  // green200: '#008000', // replaced by green100
  green300: '#cdf8c2',
  green400: '#1E7D39',
  green600: '#33cf4d',
  // green700: '#65C466', // never used

  // jadeGreen000: '#82ca9d', never used
  jadeGreen100: '#27bf81', // used for Graph

  turquoise: '#2eba9f', // used once for answer call buttons

  // red: '#FF0000', // replaced by red100
  // red000: '#F23A61',// replaced by red100
  red100: '#DC1942', //! Main
  red200: '#800021', //! Alert
  red300: '#FDD8E0', //! light
  // red400: '#FFCDD8', // replaced by red300
  // red500: '#A62639', // replaced by red200

  yellow100: '#E1CE1E',
  // yellow300: '#ffc658', // replaced by yellow 100
  // yellow400: '#FEF6EE', // never used
  yellow500: lighten('#FFFADE', 0.8),

  orange100: '#F29D3A',

  brown: '#C04D29',

  bgYellow: '#FFFADE',
  bgRed100: '#FCEDEF',
  bgRed200: '#E89BA7',
  bgGreen: '#E5F7ED',
  bgOrange: '#FEF5EB',
  bgPurple: '#F6EFFD', // used once for 'Not Started Care pathways patient record
};

export default colors;
