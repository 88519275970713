import { createTheme } from '@mui/material/styles';

import themeConfig, { useThemeConfig } from 'lib/theme/themeConfig';

// ! KEEP STATIC AND HOOK FOR TS TYPING

export const useCustomTheme = (themePalette) => {

  const newThemeConfig = useThemeConfig(themePalette)

  const theme = createTheme(newThemeConfig, {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '1rem',
        },
        contained: {
          backgroundColor: newThemeConfig.palette.common.white,
          color: newThemeConfig.palette.primary.main,
          boxShadow: '0px 10px 30px 0px rgba(0,0,0,0.1)',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation24: {
          boxShadow: '0px 10px 30px 0px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          margin: '5px 10px',
          borderRadius: '18px',
          '&[aria-selected="true"]': {
            background: `${newThemeConfig.palette.primary.main} !important`,
          },
        },
      },
    },
  });

  return theme
}

const theme = createTheme(themeConfig, {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '1rem',
      },
      contained: {
        backgroundColor: themeConfig.palette.common.white,
        color: themeConfig.palette.primary.main,
        boxShadow: '0px 10px 30px 0px rgba(0,0,0,0.1)',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation24: {
        boxShadow: '0px 10px 30px 0px rgba(0,0,0,0.1)',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      option: {
        margin: '5px 10px',
        borderRadius: '18px',
        '&[aria-selected="true"]': {
          background: `${themeConfig.palette.primary.main} !important`,
        },
      },
    },
  },
});

export default theme;
