import { createTheme } from '@mui/material/styles';
import palette, { usePalette } from 'lib/colors/palette';
import { alpha, darken } from '@mui/material/styles';
import colors, { useColors } from '../colors';

// ! KEEP STATIC AND HOOK FOR TS TYPING

const themeConfig = createTheme({
  palette: {
    colors: colors,
    primary: {
      light: alpha(palette.primary, 0.1),
      main: palette.primary,
      dark: darken(palette.primary, 0.1),
    },
    secondary: {
      light: alpha(palette.secondary, 0.1),
      main: palette.secondary,
      dark: darken(palette.secondary, 0.1),
    },
    tertiary: {
      light: alpha(palette.tertiary, 0.1),
      main: palette.tertiary,
      dark: darken(palette.tertiary, 0.1),
      contrastText: palette.textWhite,
    },
    warning: {
      light: alpha(palette.warning, 0.1),
      main: palette.warning,
    },
    error: {
      light: alpha(palette.warning, 0.1),
      main: palette.warning,
    },
    info: {
      light: alpha(palette.info, 0.1),
      main: palette.info,
    },
    success: {
      light: alpha(palette.success, 0.1),
      main: palette.success,
    },
    text: {
      primary: palette.textPrimary,
      secondary: palette.textSecondary,
    },
    background: {
      default: colors.gray100,
    },
    grey: {
      100: colors.gray100,
      200: colors.gray200,
      300: colors.gray300,
      400: colors.gray400,
      500: colors.gray500,
    },
  },
  typography: {
    fontFamily: '"Avenir Next", Roboto, Arial',
    h1: {
      fontSize: '2rem',
      fontWeight: '600',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '500',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '600',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: '600',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '1.5',
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: '600',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: '500',
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: '500',
    },
    subtitle2: {
      fontSize: '0.8125rem',
      fontWeight: '500',
    },
  },
  shape: {
    borderRadius: 6,
  },
});

export const useThemeConfig = (themePalette) => {

  const newColors = useColors(themePalette)
  const newPalette = usePalette(themePalette)

  const themeConfig = createTheme({
    palette: {
      colors: newColors,
      primary: {
        light: alpha(newPalette.primary, 0.1),
        main: newPalette.primary,
        dark: darken(newPalette.primary, 0.1),
      },
      secondary: {
        light: alpha(newPalette.secondary, 0.1),
        main: newPalette.secondary,
        dark: darken(newPalette.secondary, 0.1),
      },
      tertiary: {
        light: alpha(newPalette.tertiary, 0.1),
        main: newPalette.tertiary,
        dark: darken(newPalette.tertiary, 0.1),
        contrastText: newPalette.textWhite,
      },
      warning: {
        light: alpha(newPalette.warning, 0.1),
        main: newPalette.warning,
      },
      error: {
        light: alpha(newPalette.warning, 0.1),
        main: newPalette.warning,
      },
      info: {
        light: alpha(newPalette.info, 0.1),
        main: newPalette.info,
      },
      success: {
        light: alpha(newPalette.success, 0.1),
        main: newPalette.success,
      },
      text: {
        primary: newPalette.textPrimary,
        secondary: newPalette.textSecondary,
      },
      background: {
        default: newColors.gray100,
      },
      grey: {
        100: newColors.gray100,
        200: newColors.gray200,
        300: newColors.gray300,
        400: newColors.gray400,
        500: newColors.gray500,
      },
    },
    typography: {
      fontFamily: '"Avenir Next", Roboto, Arial',
      h1: {
        fontSize: '2rem',
        fontWeight: '600',
      },
      h2: {
        fontSize: '2rem',
        fontWeight: '500',
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: '600',
      },
      h4: {
        fontSize: '1.25rem',
        fontWeight: '600',
      },
      h5: {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '1.5',
      },
      h6: {
        fontSize: '0.875rem',
        fontWeight: '600',
      },
      body1: {
        fontSize: '1rem',
        fontWeight: '500',
      },
      subtitle1: {
        fontSize: '0.875rem',
        fontWeight: '500',
      },
      subtitle2: {
        fontSize: '0.8125rem',
        fontWeight: '500',
      },
    },
    shape: {
      borderRadius: 6,
    },
  });

  return themeConfig
}

export default themeConfig;
